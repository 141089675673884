<template>
  <div class="g-box g-resources-list-box" v-loading="loading">
    <div class="m-form-inline" data-m="select">
      <div class="m-form-inline-mn">
        <table-filter
          :show-list="showList"
          :hide-list="hideList"
          v-model="formInline"
          @filterChange="toPage(1)"
        ></table-filter>
      </div>
    </div>
    <div class="m-list" data-m="list">
      <m-table-icons
        @sizeChange="(val) => (tableSize = val)"
        :columns="tableTileTemp"
        @columnChange="(val) => (tableTile = val)"
      >
        <div slot="btns">
          <el-button
            class="u-add"
            type="primary"
            @click="addRole"
            v-if="isBank"
          >
            新增资源
          </el-button>
        </div>
      </m-table-icons>
      <el-table
        :data="tableData"
        :size="tableSize"
        stripe
        tooltip-effect="dark"
        ref="multipleTable"
        row-key="id"
      >
        <el-table-column
          :align="item.align"
          v-for="item in tableTile"
          :label="item.columnLabel"
          :prop="item.prop"
          :key="item.key"
          :width="item.width"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column align="center" fixed="right" label="操作" width="180">
          <template slot-scope="scope">
            <el-button
              circle
              icon="iconfont iconliebiao-chakan"
              title="详情"
              size="mini"
              @click="view(scope.row.id)"
              v-if="tool.checkButton('resources-info') && isBank"
            ></el-button>
            <el-button
              circle
              icon="iconfont iconliebiao-bianji"
              title="编辑"
              size="mini"
              @click="edit(scope.row.id)"
              v-if="tool.checkButton('resources-edit')"
            ></el-button>
            <el-button
              circle
              icon="iconfont iconliebiao-shanchu"
              title="删除"
              size="mini"
              @click="remove(scope.row.id)"
              v-if="isBank"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="m-page" data-m="list" v-if="formInline.page.total > 0">
      <p class="u-p">总共{{ formInline.page.total }}条数据</p>
      <el-pagination
        layout="prev, pager, next, sizes, jumper"
        :total="formInline.page.total"
        :page-size.sync="formInline.page.pageSize"
        :current-page="formInline.page.currentPage"
        @current-change="toPage"
        :page-sizes="[10, 20, 30, 40]"
        @size-change="toPage(1)"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueCookie from 'vue-cookie'
import api from './../../../api'
import tableFilter from '../../../components/table-filter'
import mTableIcons from '../../../components/m-table-icons'

export default Vue.extend({
  name: 'resources-list',
  components: { tableFilter, mTableIcons },
  directives: {},
  filters: {},
  props: {},
  data () {
    return {
      tableSize: 'medium',
      tableTileTemp: [],
      showList: [{
        copName: 'm-status',
        key: 'state',
        state: VueCookie.get('role') === 'BANKUSER' ? 'BANKUSER' : 'TENANTUSER',
        attrs: ['placeholder', 'options', 'span', 'isHide'],
        span: 3,
        isHide: VueCookie.get('role') === 'BANKUSER',
        placeholder: '用户状态',
        options: [{
          label: '超管平台',
          value: 'BANKUSER'
        }, {
          label: '园区平台',
          value: 'TENANTUSER'
        }]
      }, {
        copName: 'el-select',
        key: 'clientType',
        clientType: 'PC',
        attrs: ['placeholder', 'options'],
        placeholder: '客户端类型',
        options: [{
          label: '桌面',
          value: 'PC'
        }, {
          label: 'app',
          value: 'APP'
        }]
      }],
      hideList: [],
      formInline: {
        name: '',
        clientType: 'PC',
        state: VueCookie.get('role') === 'BANKUSER' ? 'BANKUSER' : 'TENANTUSER',
        page: {
          total: 0,
          pageSize: 10,
          currentPage: 1
        }
      },
      filter: {
        typeMap: {
          nav: '导航',
          btn: '按钮'
        }
      },
      tableData: [],
      tableTile: [{
        key: '1',
        columnLabel: '资源名称',
        align: 'left',
        prop: 'title',
        width: ''
      }, {
        key: '2',
        columnLabel: '资源代码',
        align: 'center',
        prop: 'name',
        width: ''
      }, {
        key: '3',
        columnLabel: '资源类别',
        align: 'center',
        prop: 'type',
        width: ''
      }, {
        key: '4',
        columnLabel: '状态',
        align: 'center',
        prop: 'status',
        width: ''
      }, {
        key: '5',
        columnLabel: '备注',
        align: 'center',
        prop: 'remark',
        width: ''
      }],
      loading: true
    }
  },
  computed: {
    isBank () {
      return VueCookie.get('role') === 'BANKUSER'
    }
  },
  watch: {},
  beforeCreate () {
  },
  created () {
    this.tableTileTemp = JSON.parse(JSON.stringify(this.tableTile))
    this.init()
  },
  beforeMount () {
  },
  mounted () {
  },
  activated () {
  },
  deactivated () {
  },
  beforeUpdate () {
  },
  updated () {
  },
  beforeDestroy () {
  },
  destroyed () {
  },
  methods: {
    init () {
      if (this.isBank && this.$route.query.position) {
        this.formInline.state = this.$route.query.position
      }
      this.toPage(1)
    },
    addRole () {
      this.$router.push('/authority-management/resources/resources-add')
    },
    onSubmit () {
      this.loading = true
      api.config.postMenuBuildMenu({
        body: {
          roleId: this.tool.getRoleId(this.formInline.state),
          channel: this.formInline.clientType
        }
      }).then(res => {
        this.tableData = this.getTreeData(res.data)
        // this.formInline.page.total = res.num
        this.loading = false
      })
    },
    getTreeData (list = []) {
      return list.map(item => {
        return {
          id: item.id || '',
          title: item.title || '',
          name: item.name || '',
          type: this.filter.typeMap[item.type] || '',
          status: item.show ? '显示' : '隐藏',
          remark: item.remark || '',
          children: this.getTreeData(item.node)
        }
      })
    },
    toPage (page) {
      this.formInline.page.currentPage = page
      this.onSubmit()
    },
    edit (id) {
      this.$router.push('/authority-management/resources/resources-list/resources-edit/' + id + '/' + this.tool.getRoleId(this.formInline.state))
    },
    view (id) {
      this.$router.push('/authority-management/resources/resources-list/resources-info/' + id + '/' + this.tool.getRoleId(this.formInline.state))
    },
    remove (id) {
      this.$confirm('请确认是否删除此数据', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.menu.postMenuDelete({
          id
        }).then(() => {

          this.toPage(1)
        })
      })
    }
  }
})
</script>

<style lang="scss" scoped>
@import "./../../../assets/css/global-variable";
.g-resources-list-box {
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
}
</style>

<style>
</style>
